import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import fallbackPlayerImageNBA from "./assets/nba_silhouette.png";

import nba_logo from "./assets/nba.svg";

import useMediaQuery from "@mui/material/useMediaQuery";

import Authenticate from "./components/Authenticate";
import DiscoverQuizzes from "./components/Discover/discover";
import GameController from "./components/GameController";
import Grid from "./components/Grid/grid";
import Login from "./components/Login";
import WaitingRoom from "./components/WaitingRoom";
import { WebSocketProvider } from "./contexts";

import CbsGrid from "./components/cbs/grid";
import FoolishGrid from "./components/foolish/grid";
import Leaderboard from "./components/Leaderboard"; // Import the new UserStats component
import Crossoverbballrivia from "./components/Movie/basketball";
import CrossoverMovieTrivia from "./components/Movie/game";
import PFTGrid from "./components/PFT/grid";
import Generator from "./components/RandomGenerator/generator";
import NBAGenerator from "./components/RandomGenerator/nbagenerator";
import Maintenance from "./components//404";

import GeoGuess from "./components/RandomGenerator/geo";
import Guess from "./components/RandomGenerator/GuessThePlayer";
import GuessThePlayerNBA from "./components/RandomGenerator/GuessThePlayerNBA";
import GuessThePlayerNHL from "./components/RandomGenerator/GuessThePlayerNHL";
import Wordle from "./components/RandomGenerator/wordle";
import UserStats from "./components/UserStats"; // Import the new UserStats component

import { APP_ROUTES, AUTH_PROVIDERS } from "./constants";
import March from "./components/golfgame/march";
import Proposal from "./components/golfgame/game";

import GolfLeaderboard from "./components/golfgame/GolfLeaderboard";

import MittsOffGrid from "./components/bonushockey/grid";
import BonusSoccer from "./components/bonussoccer/grid";
import GridHistory from "./components/History/grid";
import { LockerRoom } from "./components/Multiplayer";
import PGASony from "./components/Pga/pga2x2";
import PGAPresidents from "./components/Pga/pga2x4";

import PGAHistory from "./components/Pga/pgagrid";
import YesterdayGrid from "./components/yesterday/grid";
import OAuthCallbackHandler from "./pages/auth/OAuthCallbackHandler";

import {
  cfbdata,
  golfdata,
  mlbdata,
  moviesdata,
  nbaData,
  nfldata,
  nhldata,
  pgadata,
  playergolfdata,
  racingdata,
  soccerdata,newsoccerdata,
  sonydata,
  presidentdata,

  tvdata,
  wwedata,
} from "./historydata"; // Adjust the path as necessary

import mittsoff_logo from "./assets/38261270-1687523765188-3cf433f15ea61.jpg";
import pga_logo from "./assets/469145_tmpl_head_6503027a26776.png";
import om3_logo from "./assets/ab6765630000ba8a306357ce2fc584666b0a0598.jpeg";
import kenny_logo from "./assets/EB_ASSET1.png";
import kobe_logo from "./assets/EPfUW7vUEAATp25.jpg";
import f1_logo from "./assets/f1.png";
import fc_logo from "./assets/fc.svg";
import cbs_logo2 from "./assets/FFT_Podcast_-_New_Oct_21.png";
import foolish_logo from "./assets/foolish.png";
import golf_logo from "./assets/golf.svg";
import JH_logo from "./assets/JimmyHayes.png";
import jomboy_logo from "./assets/jomboy.png";
import mlb_logo from "./assets/mlb.svg";
import movies_logo from "./assets/movies.svg";
import tnt_logo from "./assets/nba_tnt.svg";
import cfb_logo from "./assets/NCAA_logo.png";
import nfl_logo from "./assets/nfl.svg";
import nhl_logo from "./assets/nhl.svg";
import pft_logo from "./assets/pft_transparent.png";
import sony_logo from "./assets/Sony_Open_in_Hawaii.svg.png";
import president_logo from "./assets/r500.png";

import ringer_logo from "./assets/the-ringer-vector-logo.png";
import tv_logo from "./assets/tv.svg";
import wrestle_logo from "./assets/wrestling.svg";

import fallbackPlayerImageNFL from "./assets/nfl_silhouette.png";
import fallbackPlayerImageNHL from "./assets/nhl_silhouette.png";
import players_logo from "./assets/PLAYERS50th-logo.png";
import Tool from "./components/aitool/tool";
import Tool2 from "./components/aitool/tool2";
import Dash from "./components/dash/dash";
import Demo from "./components/freestar/freestar";
import MathApp from "./components/Math/math";
import PressRelease from "./components/pressrelease/pressrelease";
import PrivacyPolicy from "./components/pressrelease/privacypolicy";

import "@fontsource/inter/400.css";
import "@fontsource/inter/700.css";

import { grey } from "@mui/material/colors";
import Tool3 from "./components/aitool/tool3";
import Connections from "./components/connections/connections";
import ConnectionsMLB from "./components/connections/connectionsmlb";
import ConnectionsNBA from "./components/connections/NBAConnections";
import ConnectionsNFL from "./components/connections/NFLConnections";
import Connectionstest from "./components/ConnectionsGame/ConnectionsGame";
import CrossoverGridTriviaPage from "./components/CrossoverGridTrivia/CrossoverGridTriviaPage";
import CrossoverGridTriviaPageMLB from "./components/crossovergridtriviamlb/CrossoverGridTriviaPage";
import CrossoverGridTriviaPageNBA from "./components/crossovergridtrivianba/CrossoverGridTriviaPage";

import CrossoverGridTriviaPageNFL from "./components/crossovergridtrivianfl/CrossoverGridTriviaPage";
import CrossoverGridTriviaPageNoPrime from "./components/CrossoverGridTriviaNOPRIME/CrossoverGridTriviaPage";
import Quiz from "./components/Quiz/quiz";
import { Providers } from "./domain/Providers.tsx";

const font = "'Inter', sans-serif";
const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "dark"
      ? {
          background: {
            default: "#212121",
            gameBoard: "#21212195",
            gridCard: "#ffffff30",
            gridCardHover: "#4d527080",
            gridCardSelect: "#ffffff80",
            blurCard: "#2A2A2A90",
            resultHover: "#535353",
          },
          text: {
            primary: "#ffffff",
            secondary: grey[500],
            link: "#dcdcdc",
          },
        }
      : {
          background: {
            default: "#ffffff",
            gameBoard: "#ffffff95",
            gridCard: "#ffffff30",
            gridCardHover: "#dcdcdc80",
            gridCardSelect: "#007F8B80",
            blurCard: "#ffffff95",
            resultHover: "#53535330",
          },
          text: {
            primary: grey[900],
            secondary: grey[800],
            link: "#000000",
          },
        }),
  },
  typography: {
    fontFamily: font,
  },
});

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(() => {
    return createTheme(getDesignTokens(prefersDarkMode ? "dark" : "light"));
  }, [prefersDarkMode]);

  const nbaRoutes = nbaData.map((data) => (
    <Route
      key={data.path}
      path={data.path}
      element={
        <GridHistory
          grid_id={data.gridId}
          sport_id={1}
          page_name={data.pageName}
          emoji={"🏀"}
          twitter_handle={"CrossoverGrid"}
          logo={nba_logo}
          fallbackPlayerImage={fallbackPlayerImageNBA}
          metabet={
            "metabet-sideoddstile metabet-query-bkp/nba_winner metabet-size-350x300"
          }
        />
      }
    />
  ));
  const nflRoutes = nfldata.map((data) => (
    <Route
      key={data.path}
      path={data.path}
      element={
        <GridHistory
          grid_id={data.gridId}
          sport_id={7}
          page_name={data.pageName}
          emoji={"🏈"}
          twitter_handle={"CrossoverGridFB"}
          logo={nfl_logo}
          fallbackPlayerImage={fallbackPlayerImageNFL}
          metabet={"metabet-gametile metabet-query-535905 metabet-size-350x300"}
        />
      }
    />
  ));
  const nhlRoutes = nhldata.map((data) => (
    <Route
      key={data.path}
      path={data.path}
      element={
        <GridHistory
          grid_id={data.gridId}
          sport_id={3}
          page_name={data.pageName}
          emoji={"🏒"}
          twitter_handle={"CrossoverNHL"}
          logo={nhl_logo}
          fallbackPlayerImage={fallbackPlayerImageNHL}
          metabet={
            "metabet-sideoddstile metabet-query-hkn/nhl_winner metabet-size-350x300"
          }
        />
      }
    />
  ));
  const pgaRoutes = pgadata.map((data) => (
    <Route
      key={data.path}
      path={data.path}
      element={
        <PGAHistory
          grid_id={data.gridId}
          sport_id={16}
          page_name={data.pageName}
          emoji={"⛳"}
          twitter_handle={"CrossoverPGA"}
          logo={pga_logo}
          fallbackPlayerImage={fallbackPlayerImageNHL}
        />
      }
    />
  ));
  const sonyRoutes = sonydata.map((data) => (
    <Route
      key={data.path}
      path={data.path}
      element={
        <PGASony
          grid_id={data.gridId}
          sport_id={17}
          page_name={data.pageName}
          emoji={"⛳"}
          twitter_handle={"CrossoverPGA"}
          logo={sony_logo}
          fallbackPlayerImage={fallbackPlayerImageNHL}
        />
      }
    />
  ));
  const presidentRoutes = presidentdata.map((data) => (
    <Route
      key={data.path}
      path={data.path}
      element={
        <PGAPresidents
          grid_id={data.gridId}
          sport_id={24}
          page_name={data.pageName}
          emoji={"⛳"}
          twitter_handle={"CrossoverPGA"}
          logo={president_logo}
          fallbackPlayerImage={fallbackPlayerImageNHL}
        />
      }
    />
  ));

  const playersgolfRoutes = playergolfdata.map((data) => (
    <Route
      key={data.path}
      path={data.path}
      element={
        <PGASony
          grid_id={data.gridId}
          sport_id={18}
          page_name={data.pageName}
          emoji={"⛳"}
          twitter_handle={"CrossoverPGA"}
          logo={players_logo}
          fallbackPlayerImage={fallbackPlayerImageNHL}
        />
      }
    />
  ));

  const mlbRoutes = mlbdata.map((data) => (
    <Route
      key={data.path}
      path={data.path}
      element={
        <GridHistory
          grid_id={data.gridId}
          sport_id={5}
          page_name={data.pageName}
          emoji={"⚾️"}
          twitter_handle={"CrossoverMLB"}
          logo={mlb_logo}
          fallbackPlayerImage={fallbackPlayerImageNBA}
        />
      }
    />
  ));
  const soccerRoutes = soccerdata.map((data) => (
    <Route
      key={data.path}
      path={data.path}
      element={
        <GridHistory
          grid_id={data.gridId}
          sport_id={8}
          page_name={data.pageName}
          emoji={"⚽️"}
          twitter_handle={"CrossoverGridFC"}
          logo={fc_logo}
          fallbackPlayerImage={fallbackPlayerImageNBA}
        />
      }
    />
  ));

  const newsoccerRoutes = newsoccerdata.map((data) => (
    <Route
      key={data.path}
      path={data.path}
      element={
        <GridHistory
          grid_id={data.gridId}
          sport_id={23}
          page_name={data.pageName}
          emoji={"⚽️"}
          twitter_handle={"CrossoverGridFC"}
          logo={fc_logo}
          fallbackPlayerImage={fallbackPlayerImageNBA}
        />
      }
    />
  ));
  const wweRoutes = wwedata.map((data) => (
    <Route
      key={data.path}
      path={data.path}
      element={
        <GridHistory
          grid_id={data.gridId}
          sport_id={14}
          page_name={data.pageName}
          emoji={"🤼"}
          twitter_handle={"wrestling_grid"}
          logo={wrestle_logo}
          fallbackPlayerImage={fallbackPlayerImageNBA}
        />
      }
    />
  ));

  const moviesRoutes = moviesdata.map((data) => (
    <Route
      key={data.path}
      path={data.path}
      element={
        <GridHistory
          grid_id={data.gridId}
          sport_id={12}
          page_name={data.pageName}
          emoji={"🎬🍿"}
          twitter_handle={"CrossoverMLB"}
          logo={movies_logo}
          fallbackPlayerImage={fallbackPlayerImageNBA}
        />
      }
    />
  ));
  const cfbRoutes = cfbdata.map((data) => (
    <Route
      key={data.path}
      path={data.path}
      element={
        <GridHistory
          grid_id={data.gridId}
          sport_id={10}
          page_name={data.pageName}
          emoji={"🏈"}
          twitter_handle={"CrossoverGridFB"}
          logo={cfb_logo}
          fallbackPlayerImage={fallbackPlayerImageNBA}
        />
      }
    />
  ));
  const tvRoutes = tvdata.map((data) => (
    <Route
      key={data.path}
      path={data.path}
      element={
        <GridHistory
          grid_id={data.gridId}
          sport_id={13}
          page_name={data.pageName}
          emoji={"📺"}
          twitter_handle={"CrossoverGrid"}
          logo={tv_logo}
          fallbackPlayerImage={fallbackPlayerImageNBA}
        />
      }
    />
  ));
  const racingRoutes = racingdata.map((data) => (
    <Route
      key={data.path}
      path={data.path}
      element={
        <GridHistory
          grid_id={data.gridId}
          sport_id={9}
          page_name={data.pageName}
          emoji={"🏎"}
          twitter_handle={"CrossoverGrid"}
          logo={f1_logo}
          fallbackPlayerImage={fallbackPlayerImageNBA}
        />
      }
    />
  ));
  const golfRoutes = golfdata.map((data) => (
    <Route
      key={data.path}
      path={data.path}
      element={
        <GridHistory
          grid_id={data.gridId}
          sport_id={6}
          page_name={data.pageName}
          emoji={"⛳️"}
          twitter_handle={"CrossoverPGA"}
          logo={golf_logo}
          fallbackPlayerImage={fallbackPlayerImageNBA}
        />
      }
    />
  ));

  return (
    <Providers>
      <ParallaxProvider>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route
              path="/nba"
              element={
                <Grid
                  sport={"NBA Crossover Grid"}
                  sport_id={1}
                  page_name={"nba"}
                  emoji={"🏀"}
                  twitter_handle={"CrossoverGrid"}
                  logo={nba_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route
              key={"/unlimited"}
              page_name={"Movie Trivia"}
              path={"unlimited"}
              element={<CrossoverMovieTrivia />}
            />{" "}
            <Route
              key={"/bball"}
              path={"bball"}
              page_name={"Movie Trivia"}
              element={<Crossoverbballrivia />}
            />
            <Route
              key={"/MovieQuest"}
              page_name={"Movie Trivia"}
              path={"MovieQuest"}
              element={<CrossoverMovieTrivia />}
            />
            ;
            <Route
              key={"/daily-exclusive"}
              page_name={"NBA Trivia"}
              path={"/daily-exclusive"}
              element={<CrossoverGridTriviaPageNBA />}
            />{" "}
            <Route
              key={"/nba/trivia"}
              path={"/nba/trivia"}
              page_name={"NBA Trivia"}
              element={<CrossoverGridTriviaPageNBA />}
            />
            <Route
              key={"/trivia"}
              path={"/trivia"}
              page_name={"Soccer Trivia"}
              element={<CrossoverGridTriviaPageNoPrime />}
            />
            <Route
              key={"/soccer/trivia"}
              path={"/soccer/trivia"}
              page_name={"Soccer Trivia"}
              element={<CrossoverGridTriviaPageNoPrime />}
            />
            <Route
              key={"/mlb/trivia"}
              path={"/mlb/trivia"}
              page_name={"MLB Trivia"}
              element={<CrossoverGridTriviaPageMLB />}
            />
            <Route
              key={"/nfl/trivia"}
              page_name={"NFL Trivia"}
              path={"/nfl/trivia"}
              element={<CrossoverGridTriviaPageNFL />}
            />{" "}
            <Route
              key={"/connections"}
              path={"/connections"}
              page_name={"NHL Connections"}
              element={<Connections />}
            />
            <Route
              key={"/connections/test"}
              path={"/connections/test"}
              page_name={"NHL Connections"}
              element={<Connectionstest />}
            />
            <Route
              key={"/perfect/test"}
              path={"/perfect/test"}
              sport={"Perfect 30"}
              page_name={"Perfect 30"}
              element={<March />}
            />
                   <Route
              key={"/pgaproposal"}
              path={"/pgaproposal"}
              sport={"Perfect 30"}
              page_name={"Perfect 30"}
              element={<Proposal />}
            />
                       <Route
              key={"/perfect"}
              path={"/perfect"}
              sport={"Perfect 30"}
              page_name={"Perfect 30"}
              element={<March />}
            />
            <Route
              key={"/perfect/leaderboard"}
              path={"/perfect/leaderboard"}
              sport={"Perfect 30"}
              page_name={"Perfect 30"}
              element={<GolfLeaderboard />}
            />
            <Route key={"/dash"} path={"/dash"} element={<Dash />} />{" "}
            <Route
              sport={"Crossover Grid: Daily Sports Trivia"}
              key={"/home"}
              path={"/home"}
              element={<Dash />}
            />
            <Route
              sport={"Random NBA Player Generator"}
              page_name={"Random NBA Player Generator"}
              key={"/nba/random"}
              path={"/nba/random"}
              element={<NBAGenerator />}
            />
            <Route
              sport={"Random NFL Player Generator"}
              page_name={"Random NFL Player Generator"}
              key={"/random"}
              path={"/random"}
              element={<Generator />}
            />{" "}
            <Route
              sport={"Random NFL Player Generator"}
              page_name={"Random NFL Player Generator"}
              key={"/wordle"}
              path={"/wordle"}
              element={<Wordle />}
            />{" "}
            <Route
              sport={"Random NFL Player Guesser"}
              page_name={"Random NFL Player Guesser"}
              key={"/guess"}
              path={"/guess"}
              element={<Guess />}
            />{" "}
            <Route
              sport={"Random NFL Player Guesser"}
              page_name={"Random NFL Player Guesser"}
              key={"/guess"}
              path={"/guess"}
              element={<Guess />}
            />{" "}
            <Route
              sport={"Random NFL Player Guesser"}
              page_name={"Random NFL Player Guesser"}
              key={"/nfl/geo"}
              path={"/nfl/geo"}
              element={<GeoGuess />}
            />
            <Route
              sport={"Random NFL Player Guesser"}
              page_name={"Random NFL Player Guesser"}
              key={"/guesser"}
              path={"/guesser"}
              element={<Guess />}
            />{" "}
            <Route
              sport={"Random NFL Player Guesser"}
              page_name={"Random NFL Player Guesser"}
              key={"/nfl/guesser"}
              path={"/nfl/guesser"}
              element={<Guess />}
            />
            <Route
              sport={"Random NBA Player Guesser"}
              page_name={"Random NBA Player Guesser"}
              key={"/nba/guesser"}
              path={"/nba/guesser"}
              element={<GuessThePlayerNBA />}
            />
            <Route
              sport={"Random NHL Player Guesser"}
              page_name={"Random NHL Player Guesser"}
              key={"/nhl/guesser"}
              path={"/nhl/guesser"}
              element={<GuessThePlayerNHL />}
            />
            <Route
              sport={"Random NFL Player Generator"}
              page_name={"Random NFL Player Generator"}
              key={"/nfl/random-generator"}
              path={"/nfl/random-generator"}
              element={<Generator />}
            />
            <Route
              sport={"Random NBA Player Generator"}
              page_name={"Random NBA Player Generator"}
              key={"/nba/random-generator"}
              path={"/nba/random-generator"}
              element={<NBAGenerator />}
            />
            <Route
              sport={"Crossover Grid: Daily Sports Trivia"}
              key={"/"}
              path={"/"}
              element={<Dash />}
            />
            <Route key={"/analyze"} path={"/analyze"} element={<Tool />} />
            <Route key={"/compare"} path={"/compare"} element={<Tool2 />} />
            <Route key={"/AI"} path={"/AI"} element={<Tool3 />} />
            <Route key={"/math"} path={"/math"} element={<MathApp />} />
            <Route
              key={"/nhl/connections"}
              page_name={"NHL Connections"}
              path={"/nhl/connections"}
              element={<Connections />}
            />
            <Route
              key={"/mlb/connections"}
              page_name={"MLB Connections"}
              path={"/mlb/connections"}
              element={<ConnectionsMLB />}
            />
            <Route
              key={"/nfl/connections"}
              page_name={"NFL Connections"}
              path={"/nfl/connections"}
              element={<ConnectionsNFL />}
            />
            <Route
              key={"/nba/connections"}
              page_name={"NBA Connections"}
              path={"/nba/connections"}
              element={<ConnectionsNBA />}
            />
            ;
            <Route
              path="/soccer"
              element={
                <Grid
                  sport={"Soccer / Futbol Crossover Grid"}
                  sport_id={8}
                  page_name={"fc"}
                  emoji={"⚽️"}
                  twitter_handle={"CrossoverGridFC"}
                  logo={fc_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route
              path="/nhl"
              element={
                <Grid
                  sport={"NHL Crossover Grid Hockey"}
                  sport_id={3}
                  page_name={"nhl"}
                  emoji={"🏒"}
                  twitter_handle={"CrossoverNHL"}
                  logo={nhl_logo}
                  fallbackPlayerImage={fallbackPlayerImageNHL}
                />
              }
            />
            <Route path="/play" element={<LockerRoom />} />
            <Route
              path="/movies/yesterday"
              element={
                <YesterdayGrid
                  grid_id={649}
                  sport_id={12}
                  page_name={"Yesterday 🎬🍿"}
                  emoji={"🎬🍿"}
                  twitter_handle={"CrossoverGrid"}
                  logo={movies_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route
              path="/mlb/yesterday"
              element={
                <YesterdayGrid
                  grid_id={637}
                  sport_id={5}
                  page_name={"Yesterday ⚾️"}
                  emoji={"⚾️"}
                  twitter_handle={"CrossoverMLB"}
                  logo={mlb_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route
              path="/nba/yesterday"
              element={
                <YesterdayGrid
                  grid_id={643}
                  sport_id={1}
                  page_name={"Yesterday 🏀"}
                  emoji={"🏀"}
                  twitter_handle={"CrossoverGrid"}
                  logo={nba_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route
              path="/nfl"
              element={
                <Grid
                  sport={"NFL Crossover Grid Football"}
                  sport_id={7}
                  page_name={"nfl"}
                  emoji={"🏈"}
                  twitter_handle={"CrossoverGridFB"}
                  logo={nfl_logo}
                  fallbackPlayerImage={fallbackPlayerImageNFL}
                />
              }
            />
            <Route
              path="/movies"
              element={
                <Grid
                  sport={"Movies Crossover Grid"}
                  sport_id={12}
                  page_name={"Movies"}
                  emoji={"🎬🍿"}
                  twitter_handle={"CrossoverGrid"}
                  logo={movies_logo}
                  fallbackPlayerImage={fallbackPlayerImageNFL}
                />
              }
            />
            <Route
              path="/tv"
              element={
                <Grid
                  sport={"TV Crossover Grid"}
                  sport_id={13}
                  page_name={"Television"}
                  emoji={"📺"}
                  twitter_handle={"CrossoverTrivia"}
                  logo={tv_logo}
                  fallbackPlayerImage={fallbackPlayerImageNFL}
                />
              }
            />
            <Route
              path="/mamba"
              element={
                <YesterdayGrid
                  grid_id={428}
                  sport_id={1}
                  page_name={"Mamba Day 🏀"}
                  emoji={"🏀"}
                  twitter_handle={"CrossoverGrid"}
                  logo={kobe_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route
              path="/nhl/yesterday"
              element={
                <YesterdayGrid
                  grid_id={642}
                  sport_id={3}
                  page_name={"Yesterday 🏒"}
                  emoji={"🏒"}
                  twitter_handle={"CrossoverNHL"}
                  logo={JH_logo}
                  fallbackPlayerImage={fallbackPlayerImageNHL}
                />
              }
            />
            <Route
              path="/golf/yesterday"
              element={
                <YesterdayGrid
                  grid_id={500}
                  sport_id={6}
                  page_name={"Yesterday "}
                  emoji={"⛳️"}
                  twitter_handle={"CrossoverPGA"}
                  logo={golf_logo}
                  fallbackPlayerImage={fallbackPlayerImageNHL}
                />
              }
            />
            <Route
              path="/Wrestling"
              element={
                <Grid
                  sport={"Wrestling Crossover Grid"}
                  sport_id={14}
                  page_name={"Wrestling"}
                  emoji={"🤼"}
                  twitter_handle={"Wrestling_Grid"}
                  logo={wrestle_logo}
                  fallbackPlayerImage={fallbackPlayerImageNFL}
                />
              }
            />
            <Route
              path="/racing/yesterday"
              element={
                <YesterdayGrid
                  grid_id={508}
                  sport_id={9}
                  page_name={"Yesterday "}
                  emoji={"🏎"}
                  twitter_handle={"CrossoverGridF1"}
                  logo={f1_logo}
                  fallbackPlayerImage={fallbackPlayerImageNHL}
                />
              }
            />
            <Route
              path="/racing"
              element={
                <Grid
                  sport={"Racing Crossover Grid"}
                  sport_id={9}
                  page_name={"racing"}
                  emoji={"🏎"}
                  twitter_handle={"CrossoverGridF1"}
                  logo={f1_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route
              path="/nfl/yesterday"
              element={
                <YesterdayGrid
                  grid_id={651}
                  sport_id={7}
                  page_name={"Yesterday 🏈"}
                  emoji={"🏈"}
                  twitter_handle={"CrossoverGridFB"}
                  logo={nfl_logo}
                  fallbackPlayerImage={fallbackPlayerImageNFL}
                />
              }
            />
            <Route
              path="/golf"
              element={
                <Grid
                  sport={"Golf or PGA Crossover Grid"}
                  sport_id={16}
                  page_name={"golf"}
                  emoji={"⛳️"}
                  twitter_handle={"CrossoverPGA"}
                  logo={golf_logo}
                  fallbackPlayerImage={fallbackPlayerImageNHL}
                />
              }
            />
            <Route
              path="/cfb"
              element={
                <Grid
                  sport={"College Football Crossover Grid"}
                  sport_id={10}
                  page_name={"cfb"}
                  emoji={"🏈"}
                  twitter_handle={"CrossoverGridFB"}
                  logo={cfb_logo}
                  fallbackPlayerImage={fallbackPlayerImageNFL}
                />
              }
            />
            <Route
              path="/cfb/yesterday"
              element={
                <YesterdayGrid
                  grid_id={625}
                  sport_id={10}
                  page_name={"Yesterday 🏈"}
                  emoji={"🏈"}
                  twitter_handle={"CrossoverGridFB"}
                  logo={nfl_logo}
                  fallbackPlayerImage={fallbackPlayerImageNFL}
                />
              }
            />
            <Route
              path="/mlb"
              element={
                <Grid
                  sport_id={5}
                  page_name={"mlb"}
                  emoji={"⚾️"}
                  twitter_handle={"CrossoverMLB"}
                  logo={mlb_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route
              path="/jomboy"
              element={
                <GridHistory
                  grid_id={210}
                  sport_id={5}
                  page_name={"jomboy"}
                  emoji={"⚾️"}
                  twitter_handle={"CrossoverMLB"}
                  logo={mlb_logo}
                  jomboy_logo={jomboy_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route
              path="/foolishBB"
              element={
                <FoolishGrid
                  grid_id={492}
                  sport_id={5}
                  page_name={"foolishBB"}
                  emoji={"⚾️"}
                  twitter_handle={"CrossoverMLB"}
                  logo={mlb_logo}
                  foolish_logo={foolish_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route
              path="/foolishBB/yesterday"
              element={
                <FoolishGrid
                  grid_id={246}
                  sport_id={5}
                  page_name={"foolishBB"}
                  emoji={"⚾️"}
                  twitter_handle={"CrossoverMLB"}
                  logo={mlb_logo}
                  foolish_logo={foolish_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route
              path="/cbs"
              element={
                <CbsGrid
                  grid_id={423}
                  sport_id={7}
                  page_name={"CbsGrid"}
                  emoji={"🏈"}
                  twitter_handle={"CrossoverGridFB"}
                  logo={nfl_logo}
                  cbs_logo={cbs_logo2}
                  fallbackPlayerImage={fallbackPlayerImageNFL}
                />
              }
            />
            <Route
              path="/cbb"
              element={
                <Grid
                  sport={"College Basketball Crossover Grid"}
                  sport_id={11}
                  page_name={"cbb"}
                  emoji={"🏀"}
                  twitter_handle={"CrossoverGrid"}
                  logo={cfb_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route
              path="/tv"
              element={
                <Grid
                  sport={"TV Crossover Grid"}
                  sport_id={13}
                  page_name={"Television"}
                  emoji={"📺"}
                  twitter_handle={"CrossoverTrivia"}
                  logo={tv_logo}
                  fallbackPlayerImage={fallbackPlayerImageNFL}
                />
              }
            />
            <Route
              path="/ringer"
              element={
                <GridHistory
                  grid_id={552}
                  sport_id={1}
                  page_name={"Ringer Grid"}
                  emoji={"🏀"}
                  twitter_handle={"CrossoverGrid"}
                  logo={nfl_logo}
                  ringer_logo={ringer_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route
              path="/jj"
              element={
                <GridHistory
                  grid_id={695}
                  sport_id={1}
                  page_name={"OM3 Grid"}
                  emoji={"🏀"}
                  twitter_handle={"CrossoverGrid"}
                  logo={nba_logo}
                  ringer_logo={om3_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route
              path="/Mitts"
              element={
                <MittsOffGrid
                  grid_id={589}
                  page_name={"Mitts Off Grid"}
                  emoji={"🏒"}
                  sport_id={3}
                  twitter_handle={"CrossoverNHL"}
                  logo={nhl_logo}
                  ringer_logo={mittsoff_logo}
                  fallbackPlayerImage={fallbackPlayerImageNHL}
                />
              }
            />
            <Route
              path="/futbol"
              element={
                <GridHistory
                  grid_id={2317}
                  page_name={"Futbol"}
                  emoji={"⚽️"}
                  sport_id={8}
                  twitter_handle={"CrossoverGridFC"}
                  logo={fc_logo}
                  fallbackPlayerImage={fallbackPlayerImageNHL}
                />
              }
            />
            <Route
              path="/futbol/4"
              element={
                <GridHistory
                  grid_id={683}
                  page_name={"Futbol"}
                  emoji={"⚽️"}
                  sport_id={8}
                  twitter_handle={"CrossoverGridFC"}
                  logo={fc_logo}
                  fallbackPlayerImage={fallbackPlayerImageNHL}
                />
              }
            />
            <Route
              path="/futbol/5"
              element={
                <GridHistory
                  grid_id={709}
                  page_name={"Futbol"}
                  emoji={"⚽️"}
                  sport_id={8}
                  twitter_handle={"CrossoverGridFC"}
                  logo={fc_logo}
                  fallbackPlayerImage={fallbackPlayerImageNHL}
                />
              }
            />
            <Route
              path="/futbol/6"
              element={
                <GridHistory
                  grid_id={718}
                  page_name={"Futbol"}
                  emoji={"⚽️"}
                  sport_id={8}
                  twitter_handle={"CrossoverGridFC"}
                  logo={fc_logo}
                  fallbackPlayerImage={fallbackPlayerImageNHL}
                />
              }
            />
            <Route
              path="/futbol/7"
              element={
                <GridHistory
                  grid_id={737}
                  page_name={"Futbol"}
                  emoji={"⚽️"}
                  sport_id={8}
                  twitter_handle={"CrossoverGridFC"}
                  logo={fc_logo}
                  fallbackPlayerImage={fallbackPlayerImageNHL}
                />
              }
            />
            <Route
              path="/futbol/8"
              element={
                <GridHistory
                  grid_id={751}
                  page_name={"Futbol"}
                  emoji={"⚽️"}
                  sport_id={8}
                  twitter_handle={"CrossoverGridFC"}
                  logo={fc_logo}
                  fallbackPlayerImage={fallbackPlayerImageNHL}
                />
              }
            />
            <Route
              path="/pl"
              element={
                <BonusSoccer
                  grid_id={652}
                  page_name={"Futbol Grid"}
                  emoji={"⚽️"}
                  sport_id={8}
                  twitter_handle={"CrossoverGridFC"}
                  logo={fc_logo}
                  fallbackPlayerImage={fallbackPlayerImageNHL}
                />
              }
            />
            <Route
              path="/new"
              element={
                <BonusSoccer
                  grid_id={718}
                  page_name={"Futbol Grid"}
                  emoji={"⚽️"}
                  sport_id={8}
                  twitter_handle={"CrossoverGridFC"}
                  logo={fc_logo}
                  fallbackPlayerImage={fallbackPlayerImageNHL}
                />
              }
            />
            <Route
              path="/PFT"
              element={
                <PFTGrid
                  grid_id={256}
                  sport_id={7}
                  page_name={"PFT"}
                  emoji={"🏈"}
                  twitter_handle={"CrossoverGridFB"}
                  pft_logo={pft_logo}
                  logo={nfl_logo}
                  fallbackPlayerImage={fallbackPlayerImageNFL}
                />
              }
            />
            <Route
              path="/enjoy"
              element={
                <GridHistory
                  grid_id={143}
                  sport_id={1}
                  page_name={"kenny"}
                  emoji={"🏀"}
                  twitter_handle={"CrossoverGrid"}
                  kenny_logo={kenny_logo}
                  logo={nba_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route
              path="/TNT"
              element={
                <GridHistory
                  grid_id={23}
                  sport_id={1}
                  page_name={"TNT"}
                  emoji={"🏀"}
                  twitter_handle={"CrossoverGrid"}
                  pft_logo={tnt_logo}
                  logo={nba_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route
              path="/tnt/shaq"
              element={
                <GridHistory
                  grid_id={46}
                  sport_id={1}
                  page_name={"shaq"}
                  emoji={"🏀"}
                  twitter_handle={"CrossoverGrid"}
                  pft_logo={tnt_logo}
                  logo={nba_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route
              path="/tnt/in-season"
              element={
                <GridHistory
                  grid_id={43}
                  sport_id={1}
                  page_name={"TNT"}
                  emoji={"🏀"}
                  twitter_handle={"CrossoverGrid"}
                  pft_logo={tnt_logo}
                  logo={nba_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route
              path="/spurs-suns-quiz"
              element={
                <Quiz
                  sport_id={1}
                  page_name="Spurs/Suns Quiz"
                  quiz_id={123}
                  quiz_name="Spurs/Suns Quiz"
                  answer_key={[
                    "27777",
                    "30711",
                    "27307",
                    "30604",
                    "26781",
                    "28220",
                    "27414",
                    "27323",
                    "30160",
                    "26862",
                    "28916",
                    "30397",
                    "28243",
                    "28071",
                    "28375",
                    "28054",
                    "30506",
                    "28522",
                    "28866",
                    "27801",
                    "28000",
                    "26515",
                    "30360",
                    "30302",
                    "29457",
                    "27254",
                    "26616",
                    "27085",
                    "26524",
                    "29116",
                    "30161",
                    "26412",
                    "29436",
                    "28568",
                    "30701",
                    "92433",
                  ]}
                />
              }
            />
            <Route
              path="/quiz/:grid_id/:box_id"
              element={<Quiz sport_id={1} />}
            />
            <Route
              path="/fstrtest"
              element={
                <Demo
                  sport_id={5}
                  page_name={"mlb"}
                  emoji={"⚾️"}
                  twitter_handle={"CrossoverMLB"}
                  logo={mlb_logo}
                  fallbackPlayerImage={fallbackPlayerImageNBA}
                />
              }
            />
            <Route path="/pressrelease" element={<PressRelease />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/discover" element={<DiscoverQuizzes />} />
            {nbaRoutes}
            {nflRoutes}
            {nhlRoutes}
            {mlbRoutes}
            {soccerRoutes}
            {newsoccerRoutes}

            {wweRoutes}
            {moviesRoutes}
            {cfbRoutes}
            {tvRoutes}
            {golfRoutes}
            {racingRoutes}
            {pgaRoutes}
            {sonyRoutes}
            {presidentRoutes}

            {playersgolfRoutes}
            <Route
              path="/play"
              element={
                <WebSocketProvider>
                  <LockerRoom />
                </WebSocketProvider>
              }
            />
            <Route
              path="/play/waiting"
              element={
                <WebSocketProvider>
                  <WaitingRoom />
                </WebSocketProvider>
              }
            />
            <Route
              path="/play/game"
              element={
                <WebSocketProvider>
                  <GameController />
                </WebSocketProvider>
              }
            />
            <Route path="/user/stats" element={<UserStats />} />
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/login" element={<Login isOpen={true} />} />
            <Route path="/authenticate" element={<Authenticate />} />
            {pgaRoutes} {sonyRoutes} {presidentRoutes} {playersgolfRoutes}
            <Route
              path={APP_ROUTES.AUTH_GOOGLE_CALLBACK}
              element={
                <OAuthCallbackHandler provider={AUTH_PROVIDERS.GOOGLE} />
              }
            />
            <Route
              path={APP_ROUTES.AUTH_TWITTER_CALLBACK}
              element={
                <OAuthCallbackHandler provider={AUTH_PROVIDERS.TWITTER} />
              }
            />
            <Route
              path={APP_ROUTES.AUTH_APPLE_CALLBACK}
              element={<OAuthCallbackHandler provider={AUTH_PROVIDERS.APPLE} />}
            />
          </Routes>
        </BrowserRouter>
      </ParallaxProvider>
    </Providers>
  );
}

export default App;
